import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FocusServiceService {
  private visibilitySubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(!document.hidden);

  constructor(private ngZone: NgZone) {
    this.initEventListeners();
  }

  private initEventListeners() {
    this.ngZone.runOutsideAngular(() => {
      document.addEventListener(
        'visibilitychange',
        this.onVisibilityChange.bind(this),
      );
    });
  }

  private onVisibilityChange() {
    this.ngZone.run(() => this.visibilitySubject.next(!document.hidden));
  }

  get visibility$() {
    return this.visibilitySubject.asObservable();
  }

  get focus$() {
    return this.visibilitySubject.asObservable();
  }
}
