import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { BnNgIdleService } from 'bn-ng-idle';
import { number } from 'echarts';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor() {}

  userIdleService: UserIdleService = inject(UserIdleService);
  bnIdleService: BnNgIdleService = inject(BnNgIdleService);

  startWatch() {
    this.userIdleService.startWatching();
  }

  timerStart() {
    this.userIdleService
      .onTimerStart()
      .subscribe((count) => {});
  }

  timerStop() {
    this.userIdleService.stopTimer();
  }

  killSession(router: Router) {
    this.userIdleService.onTimeout().subscribe(() => {
      localStorage.clear();
      this.userIdleService.stopTimer();
      this.userIdleService.stopWatching();
      router.navigate(['/login']);
    });
  }

  stopWatching() {
    this.userIdleService.stopWatching();
  }

  startBnWatch(router: Router) {
    const time: number = 28800;
    this.bnIdleService.startWatching(time).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        localStorage.clear();
        router.navigate(['/login']);
      }
    });
  }

  resetBnTimer() {
    this.bnIdleService.resetTimer();
  }
  stopBnTimer() {
    this.bnIdleService.stopTimer();
  }
}
