import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { User } from '../models/user';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { StorageService, TOKEN, USER_KEY } from './storage.service';
import { Observable, tap } from 'rxjs';
import {
  ChangePasswordResponse,
  LogoutResponse,
  SendVerificationResponse,
} from '../models/generic';
import { ValidateOtpResponse } from '../models/validate-otp';
import { BaseUserService } from '../../base/services/base-user.service';
import { UserInfoResponse } from '../models/user-info-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseUserService {
  authenticatedUser = new BehaviorSubject<User | null>(null);

  loggedUser: User = this.storageService.getObject(USER_KEY);

  constructor() {
    super();
  }

  logout(userId: string): Observable<LogoutResponse> {
    return this.http
      .get(this.env.externalUrls.user.baseUrl + 'users/' + userId + '/logout')
      .pipe(
        tap(() => {
          this.authenticatedUser.next(null);
        }),
      );
  }

  login(email: string, password: string): Observable<HttpResponse<User>> {
    return this.http
      .post<User>(
        this.env.externalUrls.user.baseUrl + 'users/nox/loginEmail', //users/nox/loginEmail
        {
          email: email,
          password: password,
        },
        {
          observe: 'response', // listen to headers when body is empty
        },
      )
      .pipe(
        tap((response: HttpResponse<User>) => {
          if (response.ok || response.status === 200) {
            let user = response.body;
            if (user) {
              this.storageService.setObject(USER_KEY, user);
              this.storageService.set(
                TOKEN,
                response.headers.get('Authorization') ?? '',
              );
              this.loggedUser = this.storageService.getObject(USER_KEY);
              this.authenticatedUser.next(user);
            }
          }
        }),
      );
  }

  /**
   *
   * @param currentPassword
   * @param password
   */
  resetPassword(
    userId: string,
    confirmPassword: string,
  ): Observable<HttpResponse<ChangePasswordResponse>> {
    return this.http.put<HttpResponse<ChangePasswordResponse>>(
      this.env.externalUrls.user.baseUrl + 'users/' + userId + '/password',
      { password: confirmPassword },
      {
        observe: 'response', // listen to headers when body is empty
      },
    );
  }
  /**
   *
   * @param email
   * @returns
   */
  sendVerificationCodeToEmail(
    email: string,
  ): Observable<HttpResponse<SendVerificationResponse>> {
    return this.http.post(
      this.env.externalUrls.user.baseUrl + 'users/forgot-password-new-v1',
      { email: email },
      {
        observe: 'response', // listen to headers when body is empty
        responseType: 'text', // removed <> as text support needed
      },
    );
  }

  /**
   *
   * @param email
   * @param otp
   * @returns
   */
  validateOtp(
    email: string,
    code: string,
  ): Observable<HttpResponse<ValidateOtpResponse>> {
    return this.http.post<ValidateOtpResponse>(
      this.env.externalUrls.user.baseUrl + 'users/otp/validateNew',
      { email: email, code: code },
      {
        observe: 'response', // listen to headers when body is empty
      },
    );
  }

  /**
   *
   * @param currentPassword
   * @param password
   */
  updatedPassword(
    userId: string,
    oldPassword: string,
    confirmPassword: string,
  ): Observable<HttpResponse<ChangePasswordResponse>> {
    return this.http.put<HttpResponse<ChangePasswordResponse>>(
      this.env.externalUrls.user.baseUrl + 'users/' + userId + '/passwordV1',
      {
        oldPassword: oldPassword,
        password: confirmPassword,
      },
      {
        observe: 'response', // listen to headers when body is empty
      },
    );
  }

  silogin(mobile: string, password: string) {
    return this.http.post(
      this.env.externalUrls.system_integration.loginUrl,
      { phone: mobile, password },
      { observe: 'response' },
    );
  }

  fetchUserInfo(userId: string): Observable<UserInfoResponse> {
    return this.http.get<UserInfoResponse>(
      this.env.externalUrls.user.baseUrl + 'users/userInfo/' + userId,
    );
  }

  get user(): User | null {
    return this.authenticatedUser.value;
  }
  set user(user: User | null) {
    this.authenticatedUser.next(user);
  }
}
