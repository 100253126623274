import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseUserService } from '../services/base-user.service';
import { NGXLogger } from 'ngx-logger';
import { StorageService } from '../../shared/service/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, combineLatest, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/service/auth.service';

@Component({
  selector: 'app-base',
  standalone: true,
  imports: [TranslateModule, NgxSpinnerModule],
  templateUrl: './base.component.html',
  styleUrl: './base.component.scss',
})
export class BaseComponent {
  public translateService: TranslateService = inject(TranslateService);
  public baseUserService: BaseUserService = inject(BaseUserService);
  public logger: NGXLogger = inject(NGXLogger);
  public storageService: StorageService = inject(StorageService);
  public toastr: ToastrService = inject(ToastrService);
  public spinner: NgxSpinnerService = inject(NgxSpinnerService);
  public router: Router = inject(Router);
  public authService: AuthService = inject(AuthService);
  name: string = 'Kunchok';

  constructor() {
    // Set the default language
    this.translateService.setDefaultLang('en');
  }

  switchLanguage(language: string) {
    // Set the current language
    this.translateService.use(language);
  }

  resolveAllSubscriptions(args: Array<Subject<boolean>>) {
    this.spinner.show();
    forkJoin(args).subscribe({
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  loadData(
    allObservables: Array<Observable<boolean>>,
    terminatingObs: Subject<boolean>,
  ) {
    combineLatest(allObservables).subscribe({
      next: (data: Array<boolean>) => {
        let allSubscribed = data.every((val: boolean) => val);
        if (allSubscribed) {
          terminatingObs.next(true);
          terminatingObs.complete();
        }
      },
    });
  }
}
