import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { FocusServiceService } from './focus-service.service';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private timerSubject = new BehaviorSubject<boolean>(false);
  private visibilityService = inject(FocusServiceService);
  private timerSubscription?: Subscription;

  constructor() {
    this.visibilityService.visibility$.subscribe((isVisible) => {
      if (isVisible) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    });
  }

  private startTimer() {
    if (!this.timerSubscription || this.timerSubscription.closed) {
      let isFirstEmission = true;
      this.timerSubscription = timer(0, 1 * 60 * 1000).subscribe(() => {
        if (!isFirstEmission) {
          this.timerSubject.next(true);
        } else {
          isFirstEmission = false;
        }
      });
    }
  }

  private stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  getTimerObservable() {
    return this.timerSubject.asObservable();
  }
}
