import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { InstantiatepVpower } from '../models/string-info';

export const validatePassword: ValidatorFn = (
  control: AbstractControl,
): { [key: string]: boolean } | null => {
  const value = control.value;
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /[0-9]/.test(value);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
  const valid = hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;

  if (!valid) {
    return { invalidPassword: true };
  }

  return null; // Return null when the validation passes
};
export const validateMatchPassword: ValidatorFn = (
  control: AbstractControl,
): { [key: string]: boolean } | null => {
  const passwordControl = control.get('password');
  const confirmPasswordControl = control.get('confirmPassword');

  if (
    passwordControl &&
    confirmPasswordControl &&
    passwordControl.value !== confirmPasswordControl.value
  ) {
    return { unMatch: true }; // Return true when passwords don't match
  } else {
    return null; // Return null when passwords match
  }
};

export function convertValueToBoundary(
  value: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number,
): number {
  const convertedValue =
    ((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin;
  if (convertedValue < 0) return 0;
  if (convertedValue > 100) return 100;
  return Math.round(convertedValue);
}

export function mapBackendToFrontend(
  backendValue: number,
  min: number,
  max: number,
): number {
  // The backend value is between 0 and 100
  const backendMin = 0;
  const backendMax = 100;

  // Calculate the corresponding frontend value
  const frontendValue =
    max -
    ((backendValue - backendMin) * (max - min)) / (backendMax - backendMin);

  return frontendValue;
}

export function mapBackendPercentToFrontendValue(
  backendValue: number,
  min: number,
  max: number,
): number {
  // The backend value is between 0 and 100
  const backendMin = 0;
  const backendMax = 100;

  // Calculate the corresponding frontend value
  const frontendValue =
    ((backendValue - backendMin) * (max - min)) / (backendMax - backendMin) +
    min;

  return frontendValue;
}
export function loadScript(url: string, nonce?: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.onload = resolve;
    script.onerror = reject;
    script.src = url;
    if (nonce) {
      script.setAttribute('nonce', nonce);
    }
    document.head.appendChild(script);
  });
}
export function generateNounce(length: number): string {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charsetLength = charset.length;

  // Generate an array of random bytes
  const randomValues = new Uint8Array(length);
  window.crypto.getRandomValues(randomValues);

  // Convert random values to characters from the charset
  let randomString = '';
  for (let i = 0; i < length; i++) {
    randomString += charset[randomValues[i] % charsetLength];
  }

  return randomString;
}
export function trimListsToSameSize(lists: InstantiatepVpower[][]): void {
  // Find the minimum length among all lists
  const minLength = Math.min(...lists.map((list) => list.length));

  // Trim each list to the minimum length
  for (let i = 0; i < lists.length; i++) {
    lists[i].splice(minLength);
  }
}
