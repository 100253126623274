import * as i0 from '@angular/core';
import { makeEnvironmentProviders, Injectable, Optional, NgModule } from '@angular/core';
import { Subject, merge, fromEvent, from, interval, timer, of } from 'rxjs';
import { bufferTime, filter, tap, switchMap, takeUntil, finalize, distinctUntilChanged, map } from 'rxjs/operators';
class UserIdleConfig {}
function provideUserIdleConfig(config) {
  return makeEnvironmentProviders([{
    provide: UserIdleConfig,
    useValue: config
  }]);
}

/**
 * User's idle service.
 */
class UserIdleService {
  constructor(config, _ngZone) {
    this._ngZone = _ngZone;
    this.timerStart$ = new Subject();
    this.idleDetected$ = new Subject();
    this.timeout$ = new Subject();
    /**
     * Idle value in milliseconds.
     * Default equals to 10 minutes.
     */
    this.idleMillisec = 600 * 1000;
    /**
     * Idle buffer wait time milliseconds to collect user action
     * Default equals to 1 Sec.
     */
    this.idleSensitivityMillisec = 1000;
    /**
     * Timeout value in seconds.
     * Default equals to 5 minutes.
     */
    this.timeout = 300;
    /**
     * Ping value in milliseconds.
     * Default equals to 2 minutes.
     */
    this.pingMillisec = 120 * 1000;
    /**
     * Timeout status.
     */
    this.isTimeout = false;
    /**
     * Timer of user's inactivity is in progress.
     */
    this.isInactivityTimer = false;
    this.isIdleDetected = false;
    if (config) {
      this.setConfig(config);
    }
  }
  /**
   * Start watching for user idle and setup timer and ping.
   */
  startWatching() {
    if (!this.activityEvents$) {
      this.activityEvents$ = merge(fromEvent(window, 'mousemove'), fromEvent(window, 'resize'), fromEvent(document, 'keydown'));
    }
    this.idle$ = from(this.activityEvents$);
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
    // If any of user events is not active for idle-seconds when start timer.
    this.idleSubscription = this.idle$.pipe(bufferTime(this.idleSensitivityMillisec),
    // Starting point of detecting of user's inactivity
    filter(arr => !arr.length && !this.isIdleDetected && !this.isInactivityTimer), tap(() => {
      this.isIdleDetected = true;
      this.idleDetected$.next(true);
    }), switchMap(() => this._ngZone.runOutsideAngular(() => interval(1000).pipe(takeUntil(merge(this.activityEvents$, timer(this.idleMillisec).pipe(tap(() => {
      this.isInactivityTimer = true;
      this.timerStart$.next(true);
    })))), finalize(() => {
      this.isIdleDetected = false;
      this.idleDetected$.next(false);
    }))))).subscribe();
    this.setupTimer(this.timeout);
    this.setupPing(this.pingMillisec);
  }
  stopWatching() {
    this.stopTimer();
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }
  stopTimer() {
    this.isInactivityTimer = false;
    this.timerStart$.next(false);
  }
  resetTimer() {
    this.stopTimer();
    this.isTimeout = false;
  }
  /**
   * Return observable for timer's countdown number that emits after idle.
   */
  onTimerStart() {
    return this.timerStart$.pipe(distinctUntilChanged(), switchMap(start => start ? this.timer$ : of(null)));
  }
  /**
   * Return observable for idle status changed
   */
  onIdleStatusChanged() {
    return this.idleDetected$.asObservable();
  }
  /**
   * Return observable for timeout is fired.
   */
  onTimeout() {
    return this.timeout$.pipe(filter(timeout => !!timeout), tap(() => this.isTimeout = true), map(() => true));
  }
  getConfigValue() {
    return {
      idle: this.idleMillisec / 1000,
      idleSensitivity: this.idleSensitivityMillisec / 1000,
      timeout: this.timeout,
      ping: this.pingMillisec / 1000
    };
  }
  /**
   * Set config values.
   * @param config
   */
  setConfigValues(config) {
    if (this.idleSubscription && !this.idleSubscription.closed) {
      console.error('Call stopWatching() before set config values');
      return;
    }
    this.setConfig(config);
  }
  setConfig(config) {
    if (config.idle) {
      this.idleMillisec = config.idle * 1000;
    }
    if (config.ping) {
      this.pingMillisec = config.ping * 1000;
    }
    if (config.idleSensitivity) {
      this.idleSensitivityMillisec = config.idleSensitivity * 1000;
    }
    if (config.timeout) {
      this.timeout = config.timeout;
    }
  }
  /**
   * Set custom activity events
   *
   * @param customEvents Example: merge(
   *   fromEvent(window, 'mousemove'),
   *   fromEvent(window, 'resize'),
   *   fromEvent(document, 'keydown'),
   *   fromEvent(document, 'touchstart'),
   *   fromEvent(document, 'touchend')
   * )
   */
  setCustomActivityEvents(customEvents) {
    if (this.idleSubscription && !this.idleSubscription.closed) {
      console.error('Call stopWatching() before set custom activity events');
      return;
    }
    this.activityEvents$ = customEvents;
  }
  /**
   * Setup timer.
   *
   * Counts every seconds and return n+1 and fire timeout for last count.
   * @param timeout Timeout in seconds.
   */
  setupTimer(timeout) {
    this._ngZone.runOutsideAngular(() => {
      this.timer$ = of(() => new Date()).pipe(map(fn => fn()), switchMap(startDate => interval(1000).pipe(map(() => Math.round((new Date().valueOf() - startDate.valueOf()) / 1000)),
      //   convert elapsed count to seconds
      tap(elapsed => {
        if (elapsed >= timeout) {
          this.timeout$.next(true);
        }
      }))));
    });
  }
  /**
   * Setup ping.
   *
   * Pings every ping-seconds only if is not timeout.
   * @param pingMillisec
   */
  setupPing(pingMillisec) {
    this.ping$ = interval(pingMillisec).pipe(filter(() => !this.isTimeout));
  }
}
UserIdleService.ɵfac = function UserIdleService_Factory(t) {
  return new (t || UserIdleService)(i0.ɵɵinject(UserIdleConfig, 8), i0.ɵɵinject(i0.NgZone));
};
UserIdleService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: UserIdleService,
  factory: UserIdleService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserIdleService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: UserIdleConfig,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.NgZone
    }];
  }, null);
})();

/**
 * User's idle module.
 * @deprecated since version 4.0.0  - use provideUserIdleConfig(config: UserIdleConfig) instead
 */
class UserIdleModule {
  static forRoot(config) {
    return {
      ngModule: UserIdleModule,
      providers: [provideUserIdleConfig(config)]
    };
  }
}
UserIdleModule.ɵfac = function UserIdleModule_Factory(t) {
  return new (t || UserIdleModule)();
};
UserIdleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: UserIdleModule
});
UserIdleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserIdleModule, [{
    type: NgModule,
    args: [{
      imports: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular-user-idle
 */

/**
 * Generated bundle index. Do not edit.
 */

export { UserIdleConfig, UserIdleModule, UserIdleService, provideUserIdleConfig };
