// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  apiBaseUrl: 'http://localhost:8080',
  production: false,
  envType: 'dev',
  googleMapApiKey: 'AIzaSyAu4kPdb5PVZs4rAH9GkD2J96PldefJ6e0',
  testUser: {
    email: '',
    password: '',
  },
  externalUrls: {
    user: {
      // baseUrl: 'Hello',
      baseUrl: 'https://lumconnectdevusermanagement.azurewebsites.net/v1/',
      testEmail: 'ajmehul@gmail.com',
      testPassword: 'Lumin@123',
    },
    product: {
      baseUrl: 'https://lumconnectdevproductmanagement.azurewebsites.net/v1/',
    },
    openWeather: {
      baseUrl: 'https://api.openweathermap.org/data/2.5/',
      apiKey: 'd3b03f9606b980e911cad09d578091e1',
    },
    system_integration: {
      baseUrl: 'https://nox-be-dev.azurewebsites.net/v1/nox/si',
      loginUrl: 'https://devuserserviceazure.azurewebsites.net/v1/users/login',
    },
    data_analytics: {
      baseUrl: 'https://b2bintegration.azurewebsites.net/v1/',
    },
  },
};
