import { Injectable } from '@angular/core';

export const USER_KEY = 'authenticated-user';
export const USER_ID_KEY = 'authenticated-user';
export const USER_PLANT = 'authenticated-user-plant';
export const TOKEN = 'token';
export const INVERTER_TYPE = 'inverter-type';
export const PLANT_TYPE = 'plant-type';
export const SELECTED_PLANT_ID = 'selected-plant-id';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clean(): void {
    localStorage.clear();
  }

  /**
   * Set object, json and class instance
   * @param key
   * @param value
   */
  setObject(key: string, value: object) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   *
   * @param key Fetch json, object or interface
   * @returns
   */
  getObject(key: string) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key)!);
    }
    return {};
  }
}
