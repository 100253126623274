import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from '../../shared/models/user';
import { environment } from '../../../environments/environment';
import { totalPlantsSIdata } from '../../shared/models/total-plants-si';
import { TodayAlertDetailsData } from '../../shared/models/today-alert-details';
import { TodayPowerCutDetailsData } from '../../shared/models/today-power-cut-details';
import { PlantHealthDetailsdata } from '../../shared/models/plant-health-details';
import { StorageService } from '../../shared/service/storage.service';
import { SessionService } from '../../shared/service/session.service';
import { PlantDetail } from '../../shared/models/plant';
import { TimerService } from '../../shared/service/timer.service';

@Injectable({
  providedIn: 'root',
})
export class BaseUserService {
  public http: HttpClient = inject(HttpClient);
  public location: LocationStrategy = inject(LocationStrategy);
  public router: Router = inject(Router);
  public storageService = inject(StorageService);
  public timerService = inject(TimerService);
  public env = environment;
  constructor() {}

  getuserData(): Observable<HttpResponse<Array<User>>> {
    return this.http.get<HttpResponse<Array<User>>>(
      'https://jsonplaceholder.typicode.com/users',
    );
  }
  sessionService: SessionService = inject(SessionService);
  todayAlerts = new Subject<TodayAlertDetailsData>();
  todayPowerCutDetails = new Subject<TodayPowerCutDetailsData>();
  plantHealthDetails = new Subject<PlantHealthDetailsdata>();

  todayAlertsLoading = new Subject<boolean>();
  inverterType = new Subject<boolean>();
  trendsLoading = new Subject<boolean>();
  powerCutLoading = new Subject<boolean>();
  plantDetailsLoading = new Subject<boolean>();
  selectedPlantDetail = new BehaviorSubject<PlantDetail | null>(null);
  currentData = this.selectedPlantDetail.asObservable();

  back() {
    this.location.back();
  }
}
